import { EnsureArray, EnsureSingle, RemoveNullsAndUndefineds } from "./Utils";
import { Database, Tables, Enums } from "./database.types";
import { supabase_products } from "./supabaseClient";

export type FamilyRecord = Tables<{schema: 'products'}, 'families'>;
export type QbItemRecord = Tables<{schema: 'products'}, 'qb_items'>;
export type LedTapeRecord = Tables<{schema: 'products'}, 'led_tape'>;
export type LedTapeOptionValueRecord = Tables<{schema: 'products'}, 'led_tape_option_values'>;
export type LedTapeOptionValuesConflictRecord = Tables<{schema: 'products'}, 'led_tape_option_values_conflicts'>;
export type LedTapeSpecRecord = Tables<{schema: 'products'}, 'led_tape_specs'>;
export type ChannelAssemblyRecord = Tables<{schema: 'products'}, 'channel_assemblies'>;
export type ChannelRecord = Tables<{schema: 'products'}, 'channel'>;
export type ChannelLensRecord = Tables<{schema: 'products'}, 'channel_lenses'>;
export type ChannelFinishRecord = Tables<{schema: 'products'}, 'channel_finishes'>;
export type ChannelMountingRecord = Tables<{schema: 'products'}, 'channel_mountings'>;
export type CompatibilityOverrideRecord = Tables<{schema : 'products'}, 'compatibility_overrides'>;

export type QbItemChannelAssemblyDependencies = { lenses: number[]; mountings: number[]; finishes: number[]; } | null;
export type QbItemChannelAssemblyConflicts = { lenses: number[]; mountings: number[]; finishes: number[]; } | null;

export type LedTapeOptionType = Enums<{schema: 'public'}, 'LED Tape Option Type'>;
export function IsLedTapeOptionType (value: string){
  const optionTypes: LedTapeOptionType[] = ['Color', 'Kelvin Temp', 'Lumens', 'SDCM'];
  const valid = optionTypes.includes(value as LedTapeOptionType);
  return valid;
}
export type ChannelAssemblyOptionType = 'lens' | 'mounting' | 'finish';
export function IsChannelAssemblyOptionType (value: string){
  const optionTypes: ChannelAssemblyOptionType[] = ['lens', 'mounting', 'finish'];
  const valid = optionTypes.includes(value as ChannelAssemblyOptionType);
  return valid;
}

export function IsChannelOption (value: string){
  const optionTypes: string[] = ['lens', 'mounting', 'finish'];
  const valid = optionTypes.includes(value);
  return valid;
}

export type EasySpecLedTapeRecord = Tables<{schema: 'products'}, 'easy_spec_led_tape_configs'>;
export type EasySpecChannelAssemblyRecord = Tables<{schema: 'products'}, 'easy_spec_channel_assembly_configs'>;
export type EasySpecSheetRecord = Tables<{schema: 'products'}, 'easy_spec_sheets'>;

export type LedTapeOptionSelections = {
  color: Partial<LedTapeOptionValueRecord> & Pick<LedTapeOptionValueRecord, 'value'>;
  lumens: Partial<LedTapeOptionValueRecord> & Pick<LedTapeOptionValueRecord, 'value'>;
  sdcm: Partial<LedTapeOptionValueRecord> & Pick<LedTapeOptionValueRecord, 'value'>;
  kelvinTemp: Partial<LedTapeOptionValueRecord> & Pick<LedTapeOptionValueRecord, 'value'>;
}

export const getChannelAssmeblyProduct = async (id: number): Promise<ChannelAssemblyWithOptions | null> => {

  const { data: record, error } = await supabase_products
    .from('channel_assemblies')
    .select(`
      *,
      channel (
        *, qb_items_rel:rel_qb_items_channel(
          *, qb_item:qb_items(*)
        )
      ),
      option_lenses:channel_lenses(
        *, qb_items_rel:rel_qb_items_channel_lenses(
          *, qb_item:qb_items(*)
        )
      ),
      option_finishes:channel_finishes(
        *, qb_items_rel:rel_qb_items_channel_finishes(
          *, qb_item:qb_items(*)
        )
      ),
      option_mountings:channel_mountings(
        *, qb_items_rel:rel_qb_items_channel_mountings(
          *, qb_item:qb_items(*)
        )
      )
    `)
    .eq('id', id).single();

  if(record === null || error) return null;
  
  const channel = EnsureSingle(record.channel) as NonNullable<ChannelRecord>;
  const qbItems_channel = EnsureArray(EnsureSingle(record.channel)?.qb_items_rel).map(rel => {
    const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
    return qbItem && rel ? { ...qbItem, quantity: rel.quantity } : null;
  })
  const channel_trans = { ...channel, qb_items: RemoveNullsAndUndefineds(qbItems_channel) };
  const lenses = EnsureArray(record.option_lenses).map(lens => {
    const qbItems = EnsureArray(lens.qb_items_rel).map(rel => {
      const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
      return qbItem ? { ...qbItem, quantity: rel.quantity, dependencies: rel.dependencies as QbItemChannelAssemblyDependencies, conflicts: rel.conflicts as QbItemChannelAssemblyConflicts } : null;
    });     
    return { ...lens, qb_items: RemoveNullsAndUndefineds(qbItems) };
  });
  const finishes = EnsureArray(record.option_finishes).map(finish => {
    const qbItems = EnsureArray(finish.qb_items_rel).map(rel => {
      const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
      return qbItem ? { ...qbItem, quantity: rel.quantity, dependencies: rel.dependencies as QbItemChannelAssemblyDependencies, conflicts: rel.conflicts as QbItemChannelAssemblyConflicts } : null;
    });     
    return { ...finish, qb_items: RemoveNullsAndUndefineds(qbItems) };
  });
  const mountings = EnsureArray(record.option_mountings).map(mounting => {
    const qbItems = EnsureArray(mounting.qb_items_rel).map(rel => {
      const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
      return qbItem ? { ...qbItem, quantity: rel.quantity, dependencies: rel.dependencies as QbItemChannelAssemblyDependencies, conflicts: rel.conflicts as QbItemChannelAssemblyConflicts } : null;
    });     
    return { ...mounting, qb_items: RemoveNullsAndUndefineds(qbItems) };
  });
  return {
    ...record,
    channel: channel_trans,
    options: {
      lenses: lenses,
      finishes: finishes,
      mountings: mountings
    }
  };
}

export type ChannelAssemblyOptions = {
  lenses: (ChannelLensRecord & { qb_items: (QbItemRecord & { quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts})[] })[];
  finishes: (ChannelFinishRecord & { qb_items: (QbItemRecord & { quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts})[] })[];
  mountings: (ChannelMountingRecord & { qb_items: (QbItemRecord & { quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts})[] })[];
}

export type ChannelAssemblyWithOptions = ChannelAssemblyRecord & {
  channel: ChannelRecord & { qb_items: (QbItemRecord & { quantity: number})[] };
  options: ChannelAssemblyOptions
}

export const getAllChannelAssembliesWithOptions = async (): Promise<ChannelAssemblyWithOptions[]> => {
  const { data, error} = await supabase_products
    .from('channel_assemblies')
    .select(`
      *,
      channel (
        *, qb_items_rel:rel_qb_items_channel(
          *, qb_item:qb_items(*)
        )
      ),
      option_lenses:channel_lenses(
        *, qb_items_rel:rel_qb_items_channel_lenses(
          *, qb_item:qb_items(*)
        )
      ),
      option_finishes:channel_finishes(
        *, qb_items_rel:rel_qb_items_channel_finishes(
          *, qb_item:qb_items(*)
        )
      ),
      option_mountings:channel_mountings(
        *, qb_items_rel:rel_qb_items_channel_mountings(
          *, qb_item:qb_items(*)
        )
      )
    `);
  if(data === null || error){
    console.error(error);
    return [];
  }
  const filteredChannelAssemblies = data.filter(record => record.channel !== null);
  const channelAssemblies: ChannelAssemblyWithOptions[] = filteredChannelAssemblies.map(record => {
    const channel = EnsureSingle(record.channel) as NonNullable<ChannelRecord>;
    const qbItems_channel = EnsureArray(EnsureSingle(record.channel)?.qb_items_rel).map(rel => {
      const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
      return qbItem && rel ? { ...qbItem, quantity: rel.quantity } : null;
    })
    const channel_trans = { ...channel, qb_items: RemoveNullsAndUndefineds(qbItems_channel) };
    const lenses = EnsureArray(record.option_lenses).map(lens => {
      const qbItems = EnsureArray(lens.qb_items_rel).map(rel => {
        const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
        return qbItem ? { ...qbItem, quantity: rel.quantity, dependencies: rel.dependencies as QbItemChannelAssemblyDependencies, conflicts: rel.conflicts as QbItemChannelAssemblyConflicts } : null;
      });     
      return { ...lens, qb_items: RemoveNullsAndUndefineds(qbItems) };
    });
    const finishes = EnsureArray(record.option_finishes).map(finish => {
      const qbItems = EnsureArray(finish.qb_items_rel).map(rel => {
        const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
        return qbItem ? { ...qbItem, quantity: rel.quantity, dependencies: rel.dependencies as QbItemChannelAssemblyDependencies, conflicts: rel.conflicts as QbItemChannelAssemblyConflicts } : null;
      });     
      return { ...finish, qb_items: RemoveNullsAndUndefineds(qbItems) };
    });
    const mountings = EnsureArray(record.option_mountings).map(mounting => {
      const qbItems = EnsureArray(mounting.qb_items_rel).map(rel => {
        const qbItem = EnsureSingle(EnsureSingle(rel)?.qb_item);
        return qbItem ? { ...qbItem, quantity: rel.quantity, dependencies: rel.dependencies as QbItemChannelAssemblyDependencies, conflicts: rel.conflicts as QbItemChannelAssemblyConflicts } : null;
      });     
      return { ...mounting, qb_items: RemoveNullsAndUndefineds(qbItems) };
    });
    return {
      ...record,
      channel: channel_trans,
      options: {
        lenses: lenses,
        finishes: finishes,
        mountings: mountings
      }
    }
  });

  return channelAssemblies;
}

export const getLedTapeProduct = async (id: number): Promise<LedTapeWithOptionsAndSpecs | null> => {
  const { data, error } = await supabase_products
    .from('led_tape')
    .select(`
      *,
      option_values:led_tape_option_values(
        *,
        conflicts:led_tape_option_values_conflicts!led_tape_option_conflicts_option_value_1_fkey(*)
      ),
      specs:led_tape_specs(*)
    `)
    .eq('id', id).single(); 
  if(data === null || error) return null;  
  const conflictsArr: LedTapeOptionValuesConflictRecord[] = [];
  const option_values: OptionValueWithConflicts[] = EnsureArray(data.option_values).sort((a, b) => a.display_order - b.display_order).map(val => {
    const conflicts = EnsureArray(val.conflicts);
    conflictsArr.push(...conflicts);
    return { ...val, conflicts: conflicts };
  });
  const specs: LedTapeSpecRecord[] = EnsureArray(data.specs);
  return {
    ...data,
    options: option_values,
    optionConflicts: conflictsArr,
    specs: specs
  };
}

export type LedTapeWithOptionsAndSpecs = LedTapeRecord & {
  options: OptionValueWithConflicts[];
  optionConflicts: LedTapeOptionValuesConflictRecord[];
  specs: LedTapeSpecRecord[];
};

export type OptionValueWithConflicts = LedTapeOptionValueRecord & {
  conflicts: Pick<LedTapeOptionValuesConflictRecord, 'option_value_id_2'>[];
};

export const GetAllLedTapesWithOptionsAndSpecs = async (activeOnly = false): Promise<LedTapeWithOptionsAndSpecs[]> => {
  const query = supabase_products
    .from('led_tape')
    .select(`
      *,
      option_values:led_tape_option_values(
        *,
        conflicts:led_tape_option_values_conflicts!led_tape_option_conflicts_option_value_1_fkey(*)
      ),
      specs:led_tape_specs(*)
    `);
  if(activeOnly) query.eq('active', true);
  const {data, error} = await query;
  if(data === null || error){
    console.error(error);
    return [];
  }

  const ledTapes: LedTapeWithOptionsAndSpecs[] = data.map(d => {
    const conflictsArr: LedTapeOptionValuesConflictRecord[] = [];
    const option_values: OptionValueWithConflicts[] = EnsureArray(d.option_values).sort((a, b) => a.display_order - b.display_order).map(val => {
      const conflicts = EnsureArray(val.conflicts);
      conflictsArr.push(...conflicts);
      return { ...val, conflicts: conflicts };
    });    
    const specs: LedTapeSpecRecord[] = EnsureArray(d.specs);
    return {
      ...d,
      options: option_values,
      optionConflicts: conflictsArr,
      specs: specs
    };
  });
  return ledTapes;
}

export type SlugValuePair = {
  slug: string;  // i.e., Kelvin Temp
  value: string; // i.e., 27K
}
export type LedTapeOptionValuePair = SlugValuePair & {
  slug: LedTapeOptionType;  // i.e., "Kelvin Temp"
}

export type ChannelAssembly = Partial<ChannelAssemblyRecord> & {
  channel: Partial<ChannelRecord> & { qb_items: (Partial<QbItemRecord> & { quantity: number })[] };
  lens: Partial<ChannelLensRecord> & { qb_items: (Partial<QbItemRecord> & { quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts })[] };
  mounting: Partial<ChannelMountingRecord> & { qb_items: (Partial<QbItemRecord> & { quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts })[] };
  finish: Partial<ChannelFinishRecord> & { qb_items: (Partial<QbItemRecord> & { quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts })[] };
}

export const GetChannelPartNumber = (channelAssembly: ChannelAssembly): string => {
  const skuFormula = channelAssembly.sku_formula ?? '';
  const lensCode = channelAssembly.lens.sku_code ?? '';
  const mountingCode = channelAssembly.mounting.sku_code ?? '';
  const finishCode = channelAssembly.finish.sku_code ?? '';
  let partNumber = skuFormula.replace('{lens}', lensCode);
  partNumber = partNumber.replace('{mounting}', mountingCode);
  partNumber = partNumber.replace('{finish}', finishCode);
  return partNumber;
}

export const BuildChannelAssemblyFromEzSpecCodes = (
  channelAssembly: ChannelAssemblyWithOptions, 
  ezSpecCodes: { lens: string; mounting: string; finish: string; }
): ChannelAssembly | null => {
  const lens = channelAssembly.options.lenses.find(lens => lens.easy_spec_code === ezSpecCodes.lens);
  const mounting = channelAssembly.options.mountings.find(mounting => mounting.easy_spec_code === ezSpecCodes.mounting);
  const finish = channelAssembly.options.finishes.find(finish => finish.easy_spec_code === ezSpecCodes.finish);
  if(!lens || !mounting || !finish)  return null;
  return {
    ...channelAssembly,
    lens: lens,
    mounting: mounting,
    finish: finish
  }
}

export type LedTape = Partial<LedTapeRecord> & {
  sdcm?: Partial<LedTapeOptionValueRecord> & { conflicts?: Pick<LedTapeOptionValuesConflictRecord, 'option_value_id_2'>[]};
  lumens?: Partial<LedTapeOptionValueRecord> & { conflicts?: Pick<LedTapeOptionValuesConflictRecord, 'option_value_id_2'>[]};
  kelvinTemp?: Partial<LedTapeOptionValueRecord> & { conflicts?: Pick<LedTapeOptionValuesConflictRecord, 'option_value_id_2'>[]};  
  color?: Partial<LedTapeOptionValueRecord> & { conflicts?: Pick<LedTapeOptionValuesConflictRecord, 'option_value_id_2'>[]};
  specs: Partial<LedTapeSpecRecord>;
}

export const GetLedTapePartNumber = (ledTape: LedTape): string => {
  const skuFormula = ledTape.sku_formula ?? '';
  const sdcmCode = ledTape.sdcm?.sku_code ?? '';
  const colorCode = ledTape.color?.sku_code ?? '';
  const lumensCode = ledTape.lumens?.sku_code ?? '';
  const kelvinTempCode = ledTape.kelvinTemp?.sku_code ?? '';
  let partNumber = skuFormula.replace('{SDCM}', sdcmCode);
  partNumber = partNumber.replace('{Color}', colorCode);
  partNumber = partNumber.replace('{Lumens}', lumensCode);
  partNumber = partNumber.replace('{Kelvin Temp}', kelvinTempCode);
  return partNumber;
}

export const BuildLedTapeFromOptionCodes = (
  ledTape: LedTapeWithOptionsAndSpecs,
  optionCodes: { sdcmValue: string, kelvinTempValue: string, colorValue: string, lumensValue: string },
  isEasySpecCode = false
): LedTape | null => {
  const sdcm = ledTape.options.filter(opt => opt.type ===  'SDCM').find(opt => (isEasySpecCode ? opt.easy_spec_code : opt.sku_code) === optionCodes.sdcmValue) ?? null;
  const color = ledTape.options.filter(opt => opt.type ===  'Color').find(opt => (isEasySpecCode ? opt.easy_spec_code : opt.sku_code) === optionCodes.colorValue) ?? null;
  const lumens = ledTape.options.filter(opt => opt.type ===  'Lumens').find(opt => (isEasySpecCode ? opt.easy_spec_code : opt.sku_code) === optionCodes.lumensValue) ?? null;
  const kelvinTemp = ledTape.options.filter(opt => opt.type ===  'Kelvin Temp').find(opt => (isEasySpecCode ? opt.easy_spec_code : opt.sku_code) === optionCodes.kelvinTempValue) ?? null;
  
  const specs: LedTapeSpecRecord | null = ledTape.specs.filter(spec => (
    spec.option_color === (color?.id ?? null) &&
    spec.option_kelvin_temp === (kelvinTemp?.id ?? null) &&
    spec.option_sdcm === (sdcm?.id ?? null) &&
    spec.option_lumens === (lumens?.id ?? null)
  ))[0] ?? null;  
  if(!specs) return null;
  return{
    ...ledTape,
    sdcm: sdcm ?? undefined,
    color: color ?? undefined,
    lumens: lumens ?? undefined,
    kelvinTemp: kelvinTemp ?? undefined,
    specs: specs
  }
}

export const GetAllPossibleLedTapeSkus = (ledTape: LedTapeWithOptionsAndSpecs) => {
  const skuSpecs: { specId: number, sku: string }[] = ledTape.specs.map(spec => {
    const sku = GetLedTapeSku(ledTape.sku_formula, ledTape.options, spec);
    return {
      specId: spec.id,
      sku: sku
    }
  });
  return skuSpecs;
}

export const GetLedTapeSku = (formula: string, allOptions: LedTapeOptionValueRecord[], selectedSpec: LedTapeSpecRecord) => {
  const { option_color, option_kelvin_temp, option_lumens, option_sdcm } = selectedSpec;
  const colorSkuCode = allOptions.find(opt => opt.id === option_color)?.sku_code;
  const kelvinTempSkuCode = allOptions.find(opt => opt.id === option_kelvin_temp)?.sku_code;
  const lumensSkuCode = allOptions.find(opt => opt.id === option_lumens)?.sku_code;
  const sdcmSkuCode = allOptions.find(opt => opt.id === option_sdcm)?.sku_code;
  let sku = formula;
  sku = sku.replace('{Color}', colorSkuCode ?? '');
  sku = sku.replace('{Kelvin Temp}', kelvinTempSkuCode ?? '');
  sku = sku.replace('{Lumens}', lumensSkuCode ?? '');
  sku = sku.replace('{SDCM}', sdcmSkuCode ?? '');
  return sku;
}

export const extractVariableNames = (input: string): string[] => {
  // Regular expression to match content within curly braces
  const regex = /\{(.*?)\}/g;
  const matches: string[] = [];
  let match;

  // Loop through all matches
  while ((match = regex.exec(input)) !== null) {
      // Add each captured group (variable name) to the array
      matches.push(match[1]);
  }

  return matches;
}

export const extractSlugValuePairs = (formula: string, targetCode: string): SlugValuePair[] => {
  // Determine if the formula consists solely of a variable placeholder
  const isOnlyVariable = formula === `{${formula.match(/\{(.+?)\}/)?.[1]}}`;

  // Transform the formula into a regex pattern
  const regexPattern = formula.replace(/(\{[^}]+\})/g, (match) => {
      const varName = match.slice(1, -1);  // Remove the surrounding {}
      return isOnlyVariable ? `(?<${varName}>.+)` : `(?<${varName}>\\d+)`;  // If only variable, capture all characters; otherwise, capture digits
  });

  const regex = new RegExp(`^${regexPattern}$`);
  const match = targetCode.match(regex);

  if (match && match.groups) {
      return Object.entries(match.groups).map(([key, value]) => ({
          slug: key,
          value: value as string
      }));
  } else {
      return [];
  }
}

export const selectEasySpecSheets = async (ezLedTapeId?: number, ezChannelAssemblyId?: number) => {
  const query = supabase_products.from('easy_spec_sheets').select(`*`);
  if(ezLedTapeId) query.eq('ez_led_tape_id', ezLedTapeId);
  if(ezChannelAssemblyId) query.eq('ez_channe_assembly_id', ezChannelAssemblyId);
  const { data, error } = await query;
  if(error) console.error(error);
  return data ?? [];
}

export const getEasySpecImageUrl = async (fileName: string): Promise<string | null> => {
  const { data } = await supabase_products.storage.from('easy-spec-images').getPublicUrl(fileName);
  return data?.publicUrl;
}

export const getEasySpecSheetUrl = async (fileName: string): Promise<string | null> => {
  const { data } = await supabase_products.storage.from('easy-spec-sheets').getPublicUrl(fileName);
  return data?.publicUrl;
}

export type CompatibilityOverride = {
  id: number;
  assembly: {
    id: number;
    name: string;
  } | null,
  lens: {
    id: number;
    name: string;
  } | null;
  compatible: boolean;
}

export const getCompatibilityOverrides = async (ledTapeSlug: string): Promise<CompatibilityOverride[]> => {
  const query = supabase_products.from('compatibility_overrides').select(`
    id,
    assembly:channel_assemblies(id, name),
    lens:channel_lenses(id, name),
    compatible`
  );
  query.order('assembly_id');
  query.eq('led_tape_slug', ledTapeSlug);
  const { data, error } = await query;
  if(error) console.error(error);
  if(!data) return [];
  const overrides: CompatibilityOverride[] = data.map(d => {
    return {
      id: d.id,
      assembly: EnsureSingle(d.assembly),
      lens: EnsureSingle(d.lens),
      compatible: d.compatible
    }
  });
  return overrides;
}